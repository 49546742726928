<template>
  <v-container fluid>
    <div>
      <!-- le workflow applicatif -->
      <div class="d-flex justify-center" flat tile>
        <Workflow
          width="600"
          height="180"
          :steps="workflowSteps"
          :currentStep="workflowIndex"
          :labelWidth="200"
          :lineWidth="140"
        ></Workflow>
      </div>

      <v-row justify="center">
        <v-col xl="8" lg="10" md="10" sm="10" xs="12">
          <!-- le titre et le bouton retour -->
          <div class="d-flex justify-center">
            <TitleAndReturnComponent title="Super activité" />
          </div>

          <div class="d-flex justify-center">
            <WarningEditComponent />
          </div>

          <!-- la barre de progression -->
          <v-row justify="center" no-gutters>
            <v-col xl="6" lg="8" md="8" sm="10" xs="12">
              <!-- la progess bar à afficher lors du chargement des données -->
              <v-progress-linear
                indeterminate
                :active="loading"
              ></v-progress-linear>
            </v-col>
          </v-row>

          <!-- l'affichage de la table -->
          <v-card flat outlined class="my-4">
            <v-card-title class="font-weight-regular">
              <v-row no-gutters justify="space-between">
                <div>Détail d'une super activité</div>
              </v-row>
            </v-card-title>

            <!-- le nom digital -->
            <v-card-text>
              <v-row no-gutters align="center">
                <v-col cols="5" justify="center" align-self="center">
                  <div class="capitalize font-weight-regular">nom digital</div>
                </v-col>
                <v-col cols="7" align-self="center">
                  <v-text-field
                    placeholder="Le nom digital"
                    dense
                    v-model="digitalName"
                    @blur="digitalNameFieldOut"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-divider></v-divider>
            </v-card-text>

            <!-- le nom  -->
            <v-card-text>
              <v-row no-gutters align="center">
                <v-col cols="5" justify="center" align-self="center">
                  <div class="capitalize font-weight-regular">nom complet</div>
                </v-col>
                <v-col cols="7" align-self="center">
                  <v-text-field
                    placeholder="Le nom complet"
                    dense
                    v-model="name"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-divider></v-divider>
            </v-card-text>

            <!-- l'activité principale -->
            <v-card-text>
              <v-row no-gutters align="center">
                <v-col cols="5" justify="center" align-self="center">
                  <div class="capitalize font-weight-regular">activité principale</div>
                </v-col>
                <v-col cols="7" align-self="center">
                  <v-autocomplete
                    placeholder="Sélectionner une activité principale"
                    :items="availableMainActivities"
                    item-text="digitalName"
                    no-data-text="aucune activité"
                    v-model="mainActivity"
                    return-object
                  >
                  </v-autocomplete>
                </v-col>
              </v-row>
              <v-divider></v-divider>
            </v-card-text>

            <!-- Les activités associés -->
            <v-card-text>
              <v-row no-gutters class="mb-5 mt-1" align="center">
                <v-col cols="5" justify="center" align-self="start">
                  <div class="capitalize font-weight-regular mt-2">
                    les activités associées
                  </div>
                </v-col>
                <v-col cols="7" align-self="start">
                  <!-- Affichage des activités à associé -->
                  <div v-for="(act, i) in associateActivities" :key="i">
                    <v-row
                      no-gutters
                      align="center"
                      justify="space-between"
                      class="mb-1"
                    >
                      <div>
                        {{ act.digitalName }}
                      </div>
                      <v-btn
                        icon
                        color="primary"
                        @click="removeAssociateActivity(act)"
                      >
                        <v-icon>mdi-window-close</v-icon>
                      </v-btn>
                    </v-row>
                  </div>

                  <!-- Affichage de la liste des activités associables -->
                  <div>
                    <v-row no-gutters align="center" justify="space-between">
                      <v-autocomplete
                        v-model="selectedActivity"
                        :items="availableActivities"
                        item-text="digitalName"
                        return-object
                        placeholder="Associer une activité"
                        hint="Associer une activité puis appuyer sur le bouton '+'"
                        clearable
                        no-data-text="aucune activité"
                        v-on:keyup.enter="addActivity(selectedActivity)"
                      >
                      </v-autocomplete>
                      <v-btn
                        color="primary"
                        icon
                        @click="addActivity(selectedActivity)"
                        :disabled="!selectedActivity"
                        ><v-icon>mdi-plus</v-icon></v-btn
                      >
                    </v-row>
                  </div>
                </v-col>
              </v-row>
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
              outlined
              class="ma-2 px-4 btn"
              color="primary"
              :disabled="!hasChanged"
              @click="save"
              >
                <div class="capitalize">
                  enregistrer
                </div>              
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </div>

    <AlertNotSavedModifsComponent
      :show="showAlertQuit"
      @quit="onQuitAlert"
      @notquit="onNotQuitAlert"
    />

    <!-- afficher des messages -->
    <v-snackbar
      v-model="snackbarVisible"
      :color="snackbarColor"
      :timeout="snackbarTimeout"
      :left="snackbarLeft"
      :right="snackbarRight"
      :top="snackbarTop"
      :bottom="snackbarBottom"
      >{{ snackbarMessage }}</v-snackbar
    >
  </v-container>
</template>

<script>
import Workflow from "@/components/Workflow.vue";
import WorkflowMixin from "@/components/mixins/WorkflowMixin.js";

import RoleMixin from "@/components/mixins/RoleMixin.js";
import TableViewEditWorkflowMixin from "@/components/mixins/TableViewEditWorkflowMixin.js";
import SnackBarMixin from "@/components/mixins/SnackBarMixin.js";

import AlertNotSavedModifsMixin from "@/components/mixins/AlertNotSavedModifsMixin.js";
import AlertNotSavedModifsComponent from "@/components/ui/AlertNotSavedModifsComponent.vue";

import TitleAndReturnComponent from "@/components/ui/TitleAndReturnComponent.vue";
import WarningEditComponent from "@/components/ui/WarningEditComponent.vue";

import { SuperActivitiesService } from "@/service/sfr/super_activities_service.js";
import { ActivityService } from "@/service/sfr/activity_service.js";

import { areTheSame, trimString } from "@/tools/string_tool.js";

import * as exceptions from "@/service/exception_to_message.js";

export default {
  name: "EditSuperActivity",
  components: {
    Workflow,
    TitleAndReturnComponent,
    WarningEditComponent,
    AlertNotSavedModifsComponent,
  },

  mixins: [
    WorkflowMixin,
    RoleMixin,
    TableViewEditWorkflowMixin,
    SnackBarMixin,
    AlertNotSavedModifsMixin,
  ],
  data() {
    return {
      /**en cours de chargement. */
      loading: false,

      /**le service d'accès au APIs. */
      service: null,
      serviceActivities: null,

      /** l'identifiant à éditer */
      entityId: null,

      /**La donnée originale */
      source: null,
      
      /** le nom digital */
      digitalName: null,
      /** le nom */
      name: null,
      /** l'activité principale sélectionnée */
      mainActivity: null,

      /** La liste des tous les super activité */
      allSuperActivities: [],
      /** La liste des activités principale sélectionnables */
      allAssociableActivities: [],

      /** la liste des activités associés */
      associateActivities: [],

      /** l'activité sélectionné pour l'ajout */
      selectedActivity: null,
    };
  },
  methods: {
    async load() {
      try {
        this.loading = true;

        // Récupération de la totalité des super activités
        this.allSuperActivities = await this.service.getAll();
        // Récupère la super activité
        this.source = this.allSuperActivities.find((s) => s.id == this.entityId);
        // Récupère la liste des activités
        let allActivities = await this.serviceActivities.getActivities();

        // Récupération de l'activité principale
        if (this.source.mainActivity) {
          this.mainActivity = allActivities.find((a) => a.id == this.source.mainActivity);
        }

        // Parcours de toutes les super activités pour récupérer tous les ids d'activité déjà associés
        let idsAssociates = [];
        this.allSuperActivities.forEach((s) => {
          if (s.id != this.source.id) {
            idsAssociates = idsAssociates.concat(s.activities);
          }
        });
        // Récupération de toutes les activité qui n'ont pas déjà été associés
        this.allAssociableActivities = allActivities.filter((a) => !idsAssociates.includes(a.id));

        // Les activités associés à la super activité sans l'activité principale
        this.associateActivities = this.allAssociableActivities.filter((a) => 
          this.source.activities.includes(a.id) 
          && this.source.mainActivity != a.id);

        this.init();
      } catch (error) {
        console.error(error);
        this.addErrorToSnackbar(
          "chargement des données: " +
            (exceptions.toMessage(error) || "problème technique")
        );
      } finally {
        this.loading = false;
      }
    },
    /**initialiser les données à partir de l'objet source */
    init() {
      this.digitalName = this.source.digitalName;
      this.name = this.source.name;
    },

    /** Suppression de l'activité de la liste des associés */
    removeAssociateActivity(entity) {
      this.associateActivities.splice(
        this.associateActivities.findIndex((a) => a.id == entity.id),
        1
      );
    },

    /** Ajoute une activité à la liste */
    addActivity(entity) {
      if (!entity) return;

      this.associateActivities.push(entity);
      this.selectedActivity = null;
    },

    /** Sauvegarde d'une super activité */
    async save() {

      // enregistrement 
      if (this.entityHasChanged) {
        try {
          let entity = JSON.parse(JSON.stringify(this.source));
          entity.digitalName = this.digitalName;
          entity.name = this.name;
          entity.mainActivity = this.mainActivity.id;

          entity.activities = [];
          entity.activities.push(this.mainActivity.id);

          // Récupération de tous les ids des activitéq associés
          let ids = this.associateActivities.map((a) => a.id);
          entity.activities = entity.activities.concat(ids);

          // création de la super activité
          await this.service.update(entity);
          
          this.disableAlertQuit();
          this.$router.go(-1);
        } catch (error) {
          console.error(error);
          this.addErrorToSnackbar(
            "enregistrement de la super activité : " +
              (exceptions.toMessage(error) || "problème technique")
          );
        }
      }    
    },

    /** Méthode appelée lors de l'évènement de sortie de champ nom digital */
    digitalNameFieldOut() {
      this.digitalName = trimString(this.digitalName);
    },

  },
  computed: {
    /** retourne la liste des activités non attribué à une super activité */
    availableActivities() {
      let availables = [];

      let ids = this.associateActivities.map((a) => a.id);

      // si une activité principale a été choisie, il faut le prendre en compte
      if (this.mainActivity) {
        availables = this.allAssociableActivities.filter((a) => !ids.includes(a.id) && a.id != this.mainActivity.id);        
      } else {
        availables = this.allAssociableActivities.filter((a) => !ids.includes(a.id));        
      }

      return availables;
    },

    /** Retourne la liste de toutes les activités disponibles pour une activité principale */
    availableMainActivities() {
      let availables = [];

      let ids = this.associateActivities.map((a) => a.id);

      availables = this.allAssociableActivities.filter((a) => !ids.includes(a.id));

      return availables;
    },

    completed() {
      if (!this.digitalName) return false;
      if (!this.name) return false;
      if (!this.mainActivity) return false;

      return true;
    },

    hasChanged() {
      if (!this.source) return false;

      if (!this.completed) return false;

      let changed = false;

      if (this.entityHasChanged) {
        changed = true;
      }   

      return changed;
    },
    /**retourne true si entité est modifié */
    entityHasChanged() {
      let changed = false;

      if (!areTheSame(trimString(this.digitalName), this.source.digitalName)) {
        changed = true;
      }
      if (!areTheSame(this.name, this.source.name)) {
        changed = true;
      }
      if (this.mainActivity.id != this.source.mainActivity) {
        changed = true;
      }

      if (this.entitiesAssociateHasChanged) {
        changed = true;
      }

      return changed;
    },

    /** retourne true si des activité ont été ajoutés */
    entitiesAssociateHasChanged() {
      let changed = false;

      // A-t-on ajouté des activité ?
      this.associateActivities.forEach((aa) => {
        let found = this.source.activities.find((ab) => aa.id == ab);
        if (!found) {
          changed = true;
        }
      });

      /** Pour éviter d'analyser la fonction inverse précédente, si on a déjà détecté du changement, on le retourne */
      if (changed) {
        return true;
      }

      // A-t-on supprimé des activités ?
      this.source.activities.forEach((aa) => {
        if (aa != this.source.mainActivity) {
          let found = this.associateActivities.find((ab) => aa == ab.id);
          if (!found) {
            changed = true;
          }
        }
      });

      return changed;
    },    
  },
  mounted() {
    //instanciation des services d'accès aux APIs
    this.service = new SuperActivitiesService(
      this.$api.getSuperActivitiesApi()
    );
    this.serviceActivities = new ActivityService(this.$api);

    // Initialisation du worflow
    this.initTableViewEditWorkflow(this.canEdit, 1);

    //l'identifiant de la route est passé dans l'url
    if (this.$route.params.id) {
      this.entityId = this.$route.params.id;
    }

    this.load();
  },
};
</script>

<style>
</style>